<template>
  <div class="common-page">
    <div class="common-search-box">
      <el-form :inline="true" class="demo-form-inline" :size="$store.state.settingInfo.size">
        <!-- <el-form-item label="小区" >
          <el-select v-model="searchParams.depId" placeholder="请选择小区">
            <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="物业公司" >
          
          <el-select v-model="searchParams.proId" placeholder="请选择物业公司">
            <el-option v-for="(item, index) in comList" :key="index" :label="item.proName" :value="item.proId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="searchParams.date"
            type="date"
            value-format="yyyy-MM-DD"
            format="yyyy-MM-DD"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="结束月份">
          <el-date-picker
            v-model="searchParams.startweconsumptionMonth"
            type="month"
            value-format="yyyy-MM"
            format="yyyy-MM"
            placeholder="选择月">
          </el-date-picker> -->
        <!-- </el-form-item> -->
        <el-form-item label="" class="margin-left20">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="onReset" >重置</el-button>
        </el-form-item>
      </el-form>
      <div class="common-export-btn">
        <el-button type="primary" @click="onShow" :size="$store.state.settingInfo.size">生成分账信息</el-button>
        <!-- <el-upload
          style="display:inline-block"
          class="upload-demo"
          :action="$store.state.settingInfo.baseURL+'/wemeter/imporWemeters'"
          multiple
          :before-upload="beforeUpload"
          :on-success="onSuccess"
          :show-file-list="false"
    
        >
          <el-button :loading="exportLoading" style="margin-right:10px" type="primary" :size="$store.state.settingInfo.size">导入</el-button>
        </el-upload> -->
      </div>
    </div>
    <div class="table" ref="table">
      <el-table
        border
        :data="tableData"
        :max-height="heights"
        :size="$store.state.settingInfo.size"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="50">
        </el-table-column>
        <el-table-column
          prop="propertyDto.proName"
          width="150"
          label="物业公司"
        >
        </el-table-column>
        <el-table-column
          prop="batchId"
          width="150"
          label="分账批次号">
        </el-table-column>
        <el-table-column
          prop="applyDate"
          width="150"
          label="分账申请日期">
        </el-table-column>
        <el-table-column
          prop="serialNum"
          width="160"
          label="交易流水号">
        </el-table-column>
        <el-table-column
          prop="platCusNo"
          width="160"
          label="交易平台用户编号">
        </el-table-column>
        <el-table-column
          prop="mbrCode"
          width="140"
          label="存管系统用户编号">
        </el-table-column>
        <!-- <el-table-column
          prop="isCollect"
          label="是否已汇总">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.isCollect == 0">未汇总</span>
              <span v-if="scope.row.isCollect == 1">已汇总</span>
            </div>
          </template> -->
        <!-- </el-table-column> -->
        <el-table-column
          prop="type"
          width="120"
          label="产品种类">
        </el-table-column>
        <el-table-column
          prop="operatingRevenue"
          width="140"
          label="各产品当日营业收入">
        </el-table-column>
        <el-table-column
          prop="cashOperatingRevenue"
          width="170"
          label="当日现金总营业收入">
        </el-table-column>
        <el-table-column
          prop="onlineOperatingRevenue"
          width="170"
          label="当日线上支付总营业收入">
        </el-table-column>
        <el-table-column
          prop="shopAmount"
          width="150"
          label="应分加盟店分账金额">
        </el-table-column>
        <el-table-column
          prop="otherAmount"
          width="120"
          label="其他收费">
        </el-table-column>
        <el-table-column
          prop="otherAmountRemark"
          width="170"
          label="其他收费备注">
        </el-table-column>
        <el-table-column
          prop="platAmount"
          width="170"
          label="应分平台分账金额">
        </el-table-column>
        <el-table-column
          prop="shopOwedAmount"
          width="170"
          label="应付给加盟店欠款金额">
        </el-table-column>
        <el-table-column
          prop="platOwedAmount"
          width="170"
          label="应付给平台欠款金额">
        </el-table-column>
        <el-table-column
          prop="actualShopAmount"
          width="170"
          label="实际分账加盟店金额">
        </el-table-column>
        <el-table-column
          prop="actualPlatAmount"
          width="170"
          label="实际分账平台金额">
        </el-table-column>
        <el-table-column
          prop="shopExternalAmount"
          width="170"
          label="加盟商对外支付费用金额">
        </el-table-column>
        <el-table-column
          prop="platExternalAmount"
          width="170"
          label="平台对外支付费用金额">
        </el-table-column>
        <el-table-column
          prop="shopExternalRemark"
          width="190"
          label="加盟商对外支付费用金额备注">
        </el-table-column>
        <el-table-column
          prop="platExternalRemark"
          width="170"
          label="平台对外支付费用金额备注">
        </el-table-column>
        <el-table-column
          prop="paymentDirection"
          width="170"
          label="收付方向">
        </el-table-column>
        <el-table-column
          prop="serviceProvider"
          width="170"
          label="服务商分润">
        </el-table-column>
        <el-table-column
          prop="platCollectShopAmount"
          width="190"
          label="平台自行收取加盟店营业款金额">
        </el-table-column>
        <el-table-column
          prop="shopOtherAmount"
          width="170"
          label="加盟店其他收费">
        </el-table-column>
        <el-table-column
          prop="shopOtherAmountRemark"
          width="170"
          label="加盟店其他收费备注">
        </el-table-column>
        <el-table-column
          prop="platOtherAmount"
          width="170"
          label="平台其他收费">
        </el-table-column>
        <el-table-column
          prop="platOtherAmountRemark"
          width="170"
          label="平台其他收费备注">
        </el-table-column>
        <el-table-column
          prop="platOtherAmountRemark"
          width="170"
          label="状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status == 0">分账完成</span>
              <span v-if="scope.row.status == 2">分账失败</span>
              <span v-if="scope.row.status == 3">处理中</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          width="220"
          fixed="right"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="onUpload(scope.row)"  style="margin-right:10px" type="text" :size="$store.state.settingInfo.size">分账文件上传</el-button>
            <el-button
              v-if="scope.row.rspFileNm"
              class="common-operate-btn"
              type="text"
              @click="onDown(scope.row)"
              size="small">
              分账文件结果下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <CommonPage :searchParams="searchParams" @onSizChange="onSizChange" @onCurrentChange="onCurrentChange" :total="total"></CommonPage>
    </div>
    <el-dialog title="生成分账单信息" :visible.sync="dialogFormVisible" width="400px">
      <div>
        <el-form :model="modalForm" label-width="50px" :size="$store.state.settingInfo.size">
          <el-form-item label="小区" >
            <el-select v-model="modalForm.depId" placeholder="请选择小区" >
              <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期" >
            <el-date-picker
              v-model="modalForm.date"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :size="$store.state.settingInfo.size">取 消</el-button>
        <el-button type="primary" @click="onSure" :size="$store.state.settingInfo.size" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import minxins from '@/minxins/index'
export default{
  data () {
    return {
      form: {},
      total: 0,
      operaType: 'add',
      exportLoading: false,
      projectData: [],
      dialogFormVisible: false,
      tableData: [],
      loading: false,
      depList: [],
      carList: [],
      modalForm: {
        depId: '',
        date: ''
      },
      currentInfo: {},
      comList: [],
      searchParams: {
        limit: true,
        depId: '',
        pageNum: 1,
        date: '',
        pageSize: 10
      }
    }
  },
  mixins: [minxins],
  mounted () {
    this.getInfo()
    this.getAllDerp()
    this.getAllCom()
    this.modalForm.date = this.$moment(new Date()).format('YYYY-MM-DD')
  },
  methods: {
    onSearch () {
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    getAllCom () {
      this.$fetch.post('/property/queryPropertys', {
        limit: false
      }).then(res => {
        if (res.code == 200 && res.result) {
          this.comList = res.result.list
        }
      })
    },
    onUpload (params) {
      this.$fetch.post('/ccb/uploadDivideAccount', {
        depId: params.depId,
        fileId:  params.fileId,
      }).then(res => {
        if (res.code == 200) {
          this.getInfo()
        }
        // this.$utils.exportFile(res)
      })
    },
    onDown (params) {
      this.$fetch.post('/ccb/downloadDivideAccounts', {
        depId: params.depId,
        fileId:  params.fileId,
      }, { 
        responseType: 'blob', 
      }).then(res => {
        this.$utils.exportFile(res)
      })
    },
    onShow () {
      this.dialogFormVisible = true
    },
    onSizChange (size) {
      this.searchParams.pageSize = size
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onCurrentChange (num) {
      this.searchParams.pageNum = num
      this.getInfo()
    },
    onSuccess (res) {
      this.exportLoading = false
      if (res.code == '200') {
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.onSearch()
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    beforeUpload () {
      this.exportLoading = true
    },
    onAddShow () {
      this.dialogFormVisible = true
    },
    onSure () {
      this.$fetch.get('/ccb/generateDivideAccountFile', {
        params: {
          ...this.modalForm,
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '生成成功'
          });
          this.dialogFormVisible = false
          this.getInfo()
        }
      })
      
    },
    // onEditData () {
    //   this.loading = true
    //   this.$fetch.post('/weConsumption/update', {
    //     ...this.modalForm,
    //   }).then(res => {
    //     this.loading = false
    //     if (res.code == 200) {
    //       this.$message({
    //         type: 'success',
    //         message: '修改成功!'
    //       });
    //       this.dialogFormVisible = false
    //       this.getInfo()
    //     }
    //   })
    // },
    // onEdit (params) {
    //   for(let i in this.modalForm) {
    //     this.modalForm[i] = params[i]
    //   }
    //   this.operaType = 'edit'
    //   this.dialogFormVisible = true
    // },
    getInfo () {
      this.$bus.$emit('createLoad', true)
      this.$fetch.get('/ccb/queryDivideAccounts', {
        params: {
          ...this.searchParams
        }
        
      }).then(res => {
        this.$bus.$emit('createLoad', false)
        this.tableData = []
        this.total = 0
        if (res.code == 200 && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        }
      })
    },
    onReset () {
      for(let i in this.searchParams) {
        if (i != 'limit' && i != 'pageNum' && i != 'pageSize') {
          this.searchParams[i] = null
        }
      }
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    getAllDerp () {
      this.$fetch.post('/department/queryDeps', {
        limit: false
      }).then(res => {
        if (res.code == 200 && res.result) {
          this.depList = res.result.list
        }
      })
    },
    onExport () {

    },
  }
}
</script>
<style lang="less" scoped>
.sure-bottom{
  position: absolute;
  right: 20px;
  bottom: 30px
}
</style>